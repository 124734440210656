import { Button } from "./Button";
import { Picture } from "./Picture";

export function Presentation() {
    return (
        <div className="flex flex-col bg-primary text-white text-center px-10 lg:flex-row lg:text-left">
            <div className="flex flex-col gap-8 py-10 lg:w-1/2 lg:p-0 lg:h-screen lg:justify-center lg:px-5">
                <div className="flex flex-col gap-4">
                    <h1 className="text-4xl lg:text-5xl">Daiane Moraes</h1>
                    <h2 className="text-lg font-thin lg:text-xl">Lidar com a dor nas articulações não tem que ser um fardo.</h2>
                </div>
                <div className="flex flex-col gap-2">
                    <h3 className="font-semibold">Agende sua consulta</h3>
                    <div className="flex gap-4 justify-center lg:justify-start">
                        <Button id="whatsapp_contato_osasco"
                            url="https://api.whatsapp.com/send?phone=5511976924730&text=Ol%C3%A1!%20Gostaria%20de%20agendar%20uma%20consulta%20com%20a%20Dra.%20Daiane!"
                            text="Osasco"
                        />
                        <Button id="whatsapp_contato_peruibe"
                            url="https://api.whatsapp.com/send?phone=551334517000&text=Ol%C3%A1!%20Gostaria%20de%20agendar%20uma%20consulta%20com%20a%20Dra.%20Daiane!"
                            text="Peruíbe"
                        />
                    </div>
                </div>
            </div>
            <div className="lg:w-1/2 flex items-end aspect-square">
                <Picture path="dra-daiane-moraes-1.webp" alt="Dra. Daiane Moraes | Reumatologista" blockCss="h-full w-full" />
            </div>
        </div>
    )
}