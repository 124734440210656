import { GoogleMap, LoadScript, MarkerF } from "@react-google-maps/api"

export function Maps(){
    const containerStyle = {
        width: '100%',
    };

    const center = {
        lat: -23.533377,
        lng: -46.774285,
    };
    
    return(
        <div className="hidden lg:flex lg:h-screen">
            <LoadScript googleMapsApiKey="AIzaSyDugWNUSjY1n-f_eGcHKg1ep_AYunmwPRA">
                <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={15}>
                    <MarkerF
                        position={center}
                        icon='https://maps.google.com/mapfiles/ms/icons/purple-dot.png'
                    />
                </GoogleMap>
            </LoadScript>
        </div>
    );
}