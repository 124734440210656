import { BrowserRouter, Route, Routes } from "react-router-dom";
import './App.css'
import HomePage from "./pages/HomePage";
import TagManager from 'react-gtm-module'

function App() {
  const tagManagerArgs = {
    gtmId: 'GTM-NP8M3J4'
  }
  TagManager.initialize(tagManagerArgs)

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;