export interface PictureProps {
    path: string
    alt: string
    blockCss?: string
    lazyLoading?: boolean
}

export function Picture({ path, alt, blockCss, lazyLoading }: PictureProps) {
    return (
        <img src={`images/${path}`} alt={alt} className={blockCss} loading={lazyLoading ? 'lazy' : 'eager'}></img>
    )
}