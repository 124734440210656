export interface ButtonProps {
    id: string
    url: string
    text?: string
    icon?: string
    blockCss?: string
}

export function Button({ id, url, text, icon, blockCss }: ButtonProps) {
    return (
        <a id={id}
            target="_blank" rel="noreferrer"
            href={url}>
            <button className={`${icon ? "" : "border rounded-full px-5 py-2"} ${blockCss}`}>
                {icon && <img src={`icons/${icon}.svg`} alt={icon} className="h-6 w-6" loading="lazy" />}
                {text}
            </button>
        </a>
    )
}