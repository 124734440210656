import { useState } from 'react';

export function FaqQuestions() {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index: any) => {
    setOpenIndex(openIndex === index ? null : index);
  }

  return (
    <div className="bg-light-purple w-full py-4 px-8 text-left">
      <div className="bg-light-purple text-white">
        <div
          className="cursor-pointer p-4 flex justify-between items-center"
          onClick={() => toggleAccordion(1)}
        >
          <span>Quando devo procurar um Reumatologista?</span>
          <span className={`transform transition-transform ${openIndex === 1 ? 'rotate-180' : ''}`}>
            ▼
          </span>
        </div>
        {openIndex === 1 && (
          <div className="flex flex-col p-4 font-light gap-4">
            <p>Procure um Reumatologista quando apresentar os seguintes sintomas:</p>
            <ul className="flex flex-col list-disc list-inside gap-2">
              <li>Dores, inchaço, vermelhidão e inflamação em articulações por curtos e longos períodos;</li>
              <li>Dificuldades em movimentar dedos, mãos, ombros, cotovelos, joelhos, quadris e punhos;</li>
              <li>Limitação de movimentos para realizar as atividades do dia a dia;</li>
              <li>Rigidez matinal articular, especialmente ao acordar;</li>
              <li>Dores musculares sem causa e/ou fadiga constante;</li>
              <li>Diminuição da flexibilidade da coluna.</li>
            </ul>
            <p>Notou que apresenta algum desses sintomas? Então, agende sua consulta com o Reumatologista.</p>
            <p>É possível ter qualidade de vida.</p>
          </div>
        )}
      </div>

      <div className="bg-light-purple text-white">
        <div
          className="cursor-pointer p-4 flex justify-between items-center"
          onClick={() => toggleAccordion(2)}
        >
          <span>Quais doenças trata um Reumatologista?</span>
          <span className={`transform transition-transform ${openIndex === 2 ? 'rotate-180' : ''}`}>
            ▼
          </span>
        </div>
        {openIndex === 2 && (
          <div className="flex flex-col p-4 font-light gap-2">
            <p>A Reumatologia é a especialidade focada no tratamento de problemas inflamatórios que afetam as articulações e tecidos adjacentes como ossos, ligamentos, tendões e músculos.</p>
            <p>As <b>principais doenças</b> são: Artrite Reumatoide, Fibromialgia, Lúpus, Artrose, Espondilite Anquilosante, Osteoporose, Síndrome de Sjögren e Dor Aguda ou Crônica.</p>
          </div>
        )}
      </div>

      <div className="bg-light-purple text-white">
        <div
          className="cursor-pointer p-4 flex justify-between items-center"
          onClick={() => toggleAccordion(3)}
        >
          <span>Como marcar minha consulta?</span>
          <span className={`transform transition-transform ${openIndex === 3 ? 'rotate-180' : ''}`}>
            ▼
          </span>
        </div>
        {openIndex === 3 && (
          <div className="flex flex-col p-4 font-light gap-2">
            <p>Basta clicar em um dos botões de agendamento desse site que você será direcionado ao WhatsApp e assim agendamos de acordo com a disponibilidade de horário e dia da sua preferência.</p>
            <p>Além disso, é possível escolher entre a modalidade presencial ou teleconsulta, como também se será na opção de convênio (consulte disponibilidade) ou particular.</p>
          </div>
        )}
      </div>
    </div>
  );
}
