import { Presentation } from "../components/Presentation";
import { About } from "../components/About";
import { Faq } from "../components/Faq";
import { PhotoGallery } from "../components/PhotoGallery";
import { CallToAction } from "../components/CallToAction";
import { Footer } from "../components/Footer";
import { Maps } from "../components/Maps";

function HomePage(){
    return (
        <>
            <Presentation />
            <About />
            <Faq />
            <PhotoGallery />
            <Maps />
            <CallToAction />
            <Footer />
        </>
    )
}

export default HomePage;